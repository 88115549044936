export default {
	header: {
		self: {},
		items: [
			{
			},
		]
	},
	aside: {
		self: {},
		items: [
			{
				title: "Dashboard",
				root: true,
				icon: "fas fa-tachometer-alt",
				page: "dashboard",
				bullet: "dot"
			},
			{
				title: "Materiais",
				root: true,
				icon: "fas fa-recycle",
				page: "material",
				bullet: "dot"
			},
			{
				title: "Produtos",
				root: true,
				icon: "fas fa-suitcase",
				page: "produto",
				bullet: "dot"
			},
			{
				title: "Fornecedor de Gás",
				root: true,
				icon: "fas fa-truck",
				page: "fornecedor-gas",
				bullet: "dot"
			},
			{
				title: "Feirante",
				root: true,
				icon: "fas fa-user-alt",
				page: "feirante",
				bullet: "dot"
			},
			{
				title: "Troca de Materiais",
				root: true,
				icon: "fas fa-handshake",
				page: "troca",
				bullet: "dot"
			},
			{
				title: "Usuários",
				root: true,
				icon: "fas fa-users",
				page: "usuario",
				bullet: "dot"
			},
			{
				title: "Relatórios",
				root: true,
				bullet: "dot",
				icon: "fas fa-chart-pie",
				submenu: [
					{
						title: "Vendas",
						bullet: "dot",
						page: "relatorio/relatorio01"
					},
					{
						title: "Materiais coletados",
						bullet: "dot",
						page: "relatorio/relatorio02"
					},
					{
						title: "Benefícios concedidos",
						bullet: "dot",
						page: "relatorio/relatorio03"
					},
					{
						title: "Extrato de movimentação",
						bullet: "dot",
						page: "relatorio/relatorio04"
					},
					{
						title: "Materiais trocados",
						bullet: "dot",
						page: "relatorio/relatorio05"
					},
				]
			},
			{
				title: "Administradores",
				root: true,
				icon: "fas fa-user-shield",
				page: "admin",
				bullet: "dot"
			},
			{
				title: "Grupos de Acesso",
				root: true,
				icon: "fas fa-layer-group",
				page: "group",
				bullet: "dot"
			},
		]
	}
};
