import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";

const DashboardPage = lazy(() =>
	import("./dashboard/DashboardPage")
);

const GroupPage = lazy(() =>
	import("./group/GroupPage")
);

const AdminPage = lazy(() =>
	import("./admin/AdminPage")
);

const MaterialPage = lazy(() =>
	import("./material/MaterialPage")
);

const ProdutoPage = lazy(() =>
	import("./produto/ProdutoPage")
);

const FornecedorGasPage = lazy(() =>
	import("./fornecedor-gas/FornecedorGasPage")
);

const FeirantePage = lazy(() =>
	import("./feirante/FeirantePage")
);

const TrocaPage = lazy(() =>
	import("./troca/TrocaPage")
);

const RelatorioPage = lazy(() =>
	import("./relatorio/RelatorioPage")
);

const UsuarioPage = lazy(() =>
	import("./usuario/UsuarioPage")
);

export default function HomePage() {

	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Switch>
				{
					<Redirect exact from="/" to="/dashboard" />
				}

				<Route path="/dashboard" component={DashboardPage} />
				<Route path="/material" component={MaterialPage} />
				<Route path="/produto" component={ProdutoPage} />
				<Route path="/fornecedor-gas" component={FornecedorGasPage} />
				<Route path="/troca" component={TrocaPage} />
				<Route path="/feirante" component={FeirantePage} />
				<Route path="/usuario" component={UsuarioPage} />
				<Route path="/group" component={GroupPage} />
				<Route path="/admin" component={AdminPage} />
				<Route path="/relatorio" component={RelatorioPage} />

				<Redirect to="/error/error-v6" />
			</Switch>
		</Suspense>
	);
}
